<template>
  <DsModal
    id="contact-support-modal"
    :size="!submitted ? 'md' : 'sm'"
    class="contact-support-modal"
    data-testid="contact-support-modal"
    :static="!submitted"
    @closed="onClosed"
    @opened="onOpened"
    @before-open="onBeforeOpen"
  >
    <DsModalHeader
      v-if="!submitted"
      :title="t('core.shared.components.contactSupport.title')"
      hide-close-icon
    />
    <DsModalBody>
      <DsFlash id="contact-support-flash" />
      <DsForm
        ref="formRef"
        v-slot="{ meta }"
        autocomplete="off"
        :validation-schema="validationSchema"
        :populating="populating"
        data-testid="contact-support-form"
        @submit="onSubmit"
      >
        <template v-if="!submitted">
          <div v-show="isPublic">
            <DsInput
              id="name"
              ref="nameRef"
              v-model="name"
              name="name"
              :label="
                t('core.shared.components.contactSupport.form.name.label')
              "
              maxlength="255"
              data-testid="contact-name"
            />
            <DsInput
              id="email"
              v-model="email"
              name="email"
              type="email"
              :label="
                t('core.shared.components.contactSupport.form.email.label')
              "
              :placeholder="
                t(
                  'core.shared.components.contactSupport.form.email.placeholder'
                )
              "
              autocomplete="email"
              maxlength="255"
              data-testid="contact-email"
              class="mb-2"
            />
          </div>
          <DsTextarea
            id="message"
            ref="textareaRef"
            v-model="message"
            name="message"
            data-testid="contact-message"
            :label="
              t('core.shared.components.contactSupport.form.details.label')
            "
            :placeholder="
              t(
                'core.shared.components.contactSupport.form.details.placeholder'
              )
            "
            maxlength="1200"
            :rows="5"
          />
          <p class="form-textarea-details" data-testid="message-chars-count">
            {{
              t("common.form.characters", {
                currentCharacters: normalizeBlankLines(message).length,
                characters: 1200,
              })
            }}
          </p>
          <div class="form-actions">
            <DsButton
              outline
              size="lg"
              class="form-actions-cancel"
              :disabled="loading"
              data-testid="cancel-message-button"
              @click="close"
            >
              {{ t("core.shared.components.contactSupport.buttons.cancel") }}
            </DsButton>
            <DsFormButton
              :loading="loading"
              :disabled="!meta.valid"
              size="lg"
              data-testid="send-message-button"
            >
              {{ t("core.shared.components.contactSupport.buttons.send") }}
            </DsFormButton>
          </div>
        </template>
        <template v-else>
          <div class="submitted-message-body">
            <DsFlagSolidIcon class="icon" />
            <p class="title" data-testid="submitted-message-title">
              {{ t("core.shared.components.contactSupport.submitted.title") }}
            </p>
            <p class="message" data-testid="submitted-message-message">
              {{ t("core.shared.components.contactSupport.submitted.message") }}
            </p>
          </div>
          <DsButton
            size="lg"
            class="w-full"
            data-testid="submitted-message-ok-button"
            @click="close"
          >
            {{ t("core.shared.components.contactSupport.buttons.ok") }}
          </DsButton>
        </template>
      </DsForm>
    </DsModalBody>
  </DsModal>
</template>

<script lang="ts" setup>
import { ref } from "vue";

import {
  DsButton,
  DsFlagSolidIcon,
  DsFlash,
  DsFlashNotifier,
  DsForm,
  DsFormButton,
  DsInput,
  DsModal,
  DsModalBody,
  DsModalHandler,
  DsModalHeader,
  DsTextarea,
  useDsString,
} from "@devsalsa/vue-core";

import AccountService from "@/core/modules/account/services/AccountService";
import type { ContactFormData } from "@/core/modules/account/services/AccountService.types";
import AccountHandler from "@/core/shared/helpers/Account/AccountHandler";
import { ErrorTranslator } from "@/core/shared/helpers/Error/ErrorTranslator";
import { FormHandler } from "@/core/shared/helpers/FormHandler";
import { BadRequestApiServiceError } from "@/core/shared/services/Error/ApiServiceError";

import type { SubmissionContext } from "vee-validate";
import { useI18n } from "vue-i18n";
import * as yup from "yup";

defineOptions({
  name: "ContactSupportFormModal",
});

const { t } = useI18n();
const { normalizeBlankLines } = useDsString();

const formRef = ref<InstanceType<typeof DsForm> | null>(null);
const nameRef = ref<InstanceType<typeof DsInput> | null>(null);
const textareaRef = ref<InstanceType<typeof DsTextarea> | null>(null);
const loading = ref(false);
const validationSchema = yup.object({
  name: yup
    .string()
    .trim()
    .required(
      t("core.shared.components.contactSupport.form.name.validation.required")
    )
    .min(3, t("common.error.validation.minStringChars", { min: 3 }))
    .max(255, t("common.error.validation.maxStringChars", { max: 255 })),
  email: yup
    .string()
    .required(
      t("core.shared.components.contactSupport.form.email.validation.required")
    )
    .email(t("common.form.input.email.validation.invalid"))
    .max(255, t("common.error.validation.maxStringChars", { max: 255 })),
  message: yup
    .string()
    .transform((currentValue, oldValue) => {
      return oldValue.replace(/\n/g, "\r\n").trim();
    })
    .min(
      6,
      t("core.shared.components.contactSupport.form.detailsValidation.min", {
        nb: 6,
      })
    )
    .max(
      1200,
      t("core.shared.components.contactSupport.form.detailsValidation.max", {
        nb: 1200,
      })
    )
    .required(
      t("core.shared.components.contactSupport.form.detailsValidation.required")
    ),
});

const populating = ref(false);
const submitted = ref(false);
const name = ref("");
const email = ref("");
const isPublic = ref(true);
const message = ref("");

const onBeforeOpen = async () => {
  if (AccountHandler.isAuthenticated()) {
    const accountInfo = await AccountHandler.getInfo();
    name.value = `${accountInfo.first_name} ${accountInfo.last_name}`;
    email.value = accountInfo.email;
    isPublic.value = false;
  }
};

const onOpened = () => {
  if (isPublic.value) {
    (nameRef.value as InstanceType<typeof DsInput>).focus();
  } else {
    (textareaRef.value as InstanceType<typeof DsTextarea>).focus();
  }
};

const onSubmit = async (data: ContactFormData, actions: SubmissionContext) => {
  loading.value = true;
  try {
    const response = await AccountService.contact(
      data.name,
      data.email,
      data.message
    );
    if (response.updated) {
      onClosed();
      submitted.value = true;
    }
  } catch (error) {
    if (!(error instanceof BadRequestApiServiceError)) {
      throw error;
    }
    DsFlashNotifier.error(
      ErrorTranslator.translate(error),
      "contact-support-flash"
    );
    FormHandler.setErrors(error, actions);
  } finally {
    loading.value = false;
  }
};

const close = () => {
  DsModalHandler.close("contact-support-modal");
};

const onClosed = () => {
  submitted.value = false;
  (formRef.value as InstanceType<typeof DsForm>).resetForm();
};
</script>

<style lang="scss" scoped>
.contact-support-modal {
  .form-textarea-details {
    @apply mb-5 text-sm font-normal text-gray-500;
  }

  .form-actions {
    @apply grid md:grid-cols-2 w-full gap-3 pt-5;

    &-cancel {
      @apply md:order-first order-last;
    }
  }

  .submitted-message-body {
    @apply flex flex-col items-center pb-5;

    .icon {
      @apply h-10 w-10 mt-3 mb-6;
    }

    .title {
      @apply text-xl font-semibold text-jb-gray-900 mb-2;
    }

    .message {
      @apply text-base font-normal text-jb-gray-500 mb-8;
    }
  }
}
</style>
